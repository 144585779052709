<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <!-- <b-embed
        type="iframe"
        src="/api/richs/dashboard"
        allowfullscreen
        class="rounded mb-50"
      /> -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BEmbed } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BEmbed,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data
    //     console.log('edata', this.data)

    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //   })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
